<template>
  <div class="no-data">
    <!-- <img src="~assets/img/index/no-data.png" alt=""> -->
    <img src="~/assets/img/index/no-data-black.svg" alt="" v-if="supportedTheme === SUPPORTED_THEME[0]">
    <img src="~/assets/img/index/no-data-white.svg" alt="" v-if="supportedTheme === SUPPORTED_THEME[1]">
    {{text || $t('common.no_data')}}
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SUPPORTED_THEME } from 'bgconfig';
export default {
  data: () => {
    return {
      SUPPORTED_THEME
    }
  },
  props: {
    text: {
      type: String,
      default: null
    },
    defaultTheme: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      theme: state => state.theme,
      // positions: state => state.newTrading.position
    }),
    supportedTheme(){
      return this.defaultTheme || this.theme;
    }
  }
}
</script>

<style lang="less" scoped>
.no-data{
  min-height: 190px;margin: 0 auto;padding: 80px 0 0;text-align: center;font-size: 14px;color: #a7b1bb;line-height: 12px;
  img{
    display: block;height: 84px;margin: 0 auto 17px;
  }
}
</style>
