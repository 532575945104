<template>
  <client-only>
    <!-- 先去登录 -->
    <el-dialog :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" custom-class="challenge-dialog-box" title="" :width="'440px'" center :visible.sync="dialogShow">
      <div class="dialog-box">
        <div class="dialog-close" @click="dialogShow=false"><i class="iconfont icon-close2"></i></div>
        <img src="~/assets/img/challenge/must-login.png" alt="">
        <div class="succ-text">{{$t('activity_challenge.must_login')}}</div>
        <div class="form-btn center">
          <challenge-btn :text="$t('activity_challenge.btn_confirm')" @click="goLogin"></challenge-btn>
        </div>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </client-only>
</template>

<script>
import challengeBtn from '@/components/challenge/challengeBtn'
export default {
  props: {
    show: Boolean
  },
  components: {
    challengeBtn
  },
  data() {
    return {
      dialogShow: false
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.dialogShow = val;
        }
      },
      immediate: true
    },
    dialogShow(val) {
      if (!val) {
        this.$emit("update:show", false);
      }
    }
  },
  methods: {
    // 去登录
    goLogin() {
      this.$router.push({
        path: this.$i18n.path("login"),
        query: { from: "challenging" }
      });
    },
  },
}
</script>

<style lang="less">
@import url("../../less/challengeing.less");
</style>
