<template>
  <button class="challenge-btn-wrap" :class="{big: big, gray: gray}" :disabled="disabled" @click="selfClick">
    <img class="btn-loading" v-if="disabled" src="~assets/img/challenge/loading.svg" alt="">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean
    },
    gray: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    selfClick() {
      if(this.gray) {
        return;
      }
      this.$emit("click");
    }
  }
};
</script>

<style lang="less">
.challenge-btn-wrap {
  display: inline-block;
  width: 140px;
  height: 42px;
  color: #fff;
  font-size: 14px;
  line-height: 42px;
  border: none;
  background: url("~assets/img/challenge/btn-bg.png") top center no-repeat;
  background-size: auto 100%;
  .btn-loading{
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    animation: circle 1.5s infinite linear;
  }
  @keyframes circle{
    0%{ transform:rotate(0deg); }
    100%{ transform:rotate(360deg); }
  }

  &.big {
    width: 390px;
    height: 75px;
    font-size: 24px;
    line-height: 75px;
    background: url("~assets/img/challenge/btn-bg-big.png") top center no-repeat;
    background-size: auto 100%;
    .btn-loading{
      width: 24px;
      height: 24px;
    }
  }

  &:disabled{
    cursor: not-allowed;
  }
  &.gray{
    background: url("~assets/img/challenge/btn-gray-bg.png") top center no-repeat;
    background-size: auto 100%;
    cursor: not-allowed;
  }

}
</style>
