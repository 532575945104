<template>
  <client-only>
    <!-- 再去实名 -->
    <el-dialog :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" custom-class="challenge-dialog-box" title="" :width="'440px'" center :visible.sync="dialogShow"
    >
      <div class="dialog-box">
        <div class="dialog-close" @click="dialogShow = false">
          <i class="iconfont icon-close2"></i>
        </div>
        <img src="~/assets/img/challenge/must-id.png" alt="" />
        <div class="succ-text">{{ $t("activity_challenge.must_id") }}</div>
        <div class="succ-subtext">
          {{ $t("activity_challenge." + (isJoin ? 'must_id_join_tip' : 'must_id_tip')) }}
        </div>
        <div class="form-btn center">
          <challenge-btn :text="$t('activity_challenge.btn_confirm')" @click="goVerified" ></challenge-btn>
        </div>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
  </client-only>
</template>
<script>
import challengeBtn from '@/components/challenge/challengeBtn'
export default {
  props: {
    show: Boolean,
    isJoin: Boolean
  },
  components: {
    challengeBtn
  },
  data() {
    return {
      dialogShow: false
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.dialogShow = val;
        }
      },
      immediate: true
    },
    dialogShow(val) {
      if (!val) {
        this.$emit("update:show", false);
      }
    }
  },
  methods: {
    // 去实名
    goVerified() {
      this.$router.push(this.$i18n.path("account/verified"));
    }
  }
};
</script>

<style lang="less">
@import url("../../less/challengeing.less");
</style>
