// @flow
/**
 * 涉及到合约交易的各种公式
 * 其中涉及到的holdCount等字段，应当是已有+冻结
 */

// $flow-disable-line
import { Decimal } from 'decimal.js';
import forwardContractCalculator from './ForwardContractCalculator';
import reverseContractCalculator from './ReverseContractCalculator';
import type { UnionPositionType } from './CommonCalculator';
import { HoldSideEnum, SERVER_CAL_PLACE } from './CommonCalculator';

export { HoldSideEnum };

const D_ZERO = new Decimal(0);
const D_ONE = new Decimal(1);

/**
 * 计算收益 **
 * @param positionType 仓位方向
 * @param volume 持仓张数
 * @param calPrice 计算用价格【标记价格，市价等】
 * @param averageOpenPrice 平均开仓价格
 * @param isReverse
 */
export function getProfits(
  positionType: number,
  volume: Decimal,
  calPrice: Decimal,
  averageOpenPrice: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.getProfits(
        positionType,
        volume,
        calPrice,
        averageOpenPrice
      )
    : forwardContractCalculator.getProfits(
        positionType,
        volume,
        calPrice,
        averageOpenPrice
      );
}

/**
 * 计算保证金率 （保证金+未实现盈亏 ）/ 仓位价值 **
 * @param holdCount 持仓张数
 * @param marginCount 保证金数量
 * @param markPrice 合理标记价格
 * @param upnl
 * @param isReverse
 * @return {Decimal}
 */
export function calMarginRate(
  holdCount: Decimal,
  marginCount: Decimal,
  markPrice: Decimal,
  upnl: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calMarginRate(holdCount, marginCount, markPrice, upnl)
    : forwardContractCalculator.calMarginRate(holdCount, marginCount, markPrice, upnl);
}

/**
 * 计算破产价 **
 * @param positionType
 * @param holdCount 持仓张数
 * @param averageOpenPrice 平均开仓价格
 * @param marginCount 保证金数量
 * @param isReverse
 */
export function calBankruptcyPrice(
  positionType: number,
  holdCount: Decimal,
  averageOpenPrice: Decimal,
  marginCount: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calBankruptcyPrice(
        positionType,
        holdCount,
        averageOpenPrice,
        marginCount
      )
    : forwardContractCalculator.calBankruptcyPrice(
        positionType,
        holdCount,
        averageOpenPrice,
        marginCount
      );
}

/**
 * 计算保证金 **
 * @param count 仓位张数
 * @param markPrice 标记价格
 * @param leverage 杠杆倍数
 * @param isReverse
 */
export function calMarginCount(
  count: Decimal,
  markPrice: Decimal,
  leverage: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calMarginCount(count, markPrice, leverage)
    : forwardContractCalculator.calMarginCount(count, markPrice, leverage);
}

/**
 * 计算开仓成本 **
 * (平均开仓价格仓位价值／委托的杠杆倍数) * (1+冻结保证金上浮比例) + (平均开仓价格仓位价值*手续费率）* (1+冻结手续费上浮比例)
 * @param count 开仓张数
 * @param marketPrice 标记价格 或者 市场价格
 * @param leverage 杠杆倍数
 * @param feeRate 手续费率
 * @param openCostUpRatio 开仓成本上浮比例
 * @param openCostFeeUpRatio 开仓手续费上浮比例
 * @param isReverse
 */
export function calOpenPositionCost(
  count: Decimal,
  marketPrice: Decimal,
  leverage: Decimal,
  feeRate: Decimal,
  openCostUpRatio: Decimal,
  openCostFeeUpRatio: Decimal,
  isReverse: boolean = true
): Decimal {
  if (leverage.isZero() || marketPrice.isZero()) {
    return new Decimal(0);
  }
  let openPriceValue = calValue(count, marketPrice, isReverse);

  return openPriceValue
    .div(leverage)
    .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP)
    .times(openCostUpRatio.add(1))
    .add(openPriceValue.times(feeRate).times(openCostFeeUpRatio.add(1)))
    .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
}

/**
 * 计算最大可开张数 **
 * 反向：用户可用余额 * 用户开仓价格 / {[(1 + 手续费费率 + 开仓成本上浮比例)/ 杠杆倍率 + 手续费费率 + 开仓成本上浮比例 ] * 合约面值}
 * 正向：全部可用资产/{[（1+冻结保证金上浮比例）/当前杠杆+手续费率*（1+冻结手续费上浮比例）]*合约面值 * 平均开仓价格}
 * @param balance 用户可用余额
 * @param calPrice 填写的开仓价格
 * @param feeRate 手续费率
 * @param openCostUpRatio 开仓成本上浮比例
 * @param openCostFeeUpRatio 开仓手续费上浮比例 新增
 * @param userLeverage 杠杆倍数
 * @param gradientMaxCount 梯度最大可开张数
 * @param holdCount 当前持仓张数
 * @param delegateCount 开仓委托张数
 * @param isReverse
 */
export function calMaxOpenContractCount(
  balance: Decimal,
  calPrice: Decimal,
  feeRate: Decimal,
  openCostUpRatio: Decimal,
  openCostFeeUpRatio: Decimal,
  userLeverage: Decimal,
  gradientMaxCount: Decimal,
  holdCount: Decimal,
  delegateCount: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calMaxOpenContractCount(
      balance,
      calPrice,
      feeRate,
      openCostUpRatio,
      openCostFeeUpRatio,
      userLeverage,
      gradientMaxCount,
      holdCount,
      delegateCount
    )
    : forwardContractCalculator.calMaxOpenContractCount(
      balance,
      calPrice,
      feeRate,
      openCostUpRatio,
      openCostFeeUpRatio,
      userLeverage,
      gradientMaxCount,
      holdCount,
      delegateCount
    );
}

/**
 * 计算单位杠杆净盈利 **
 * 单位杠杆净盈利排序 = 盈利百分比*有效杠杆(如果盈利，未实现盈亏＞0)
 * 单位杠杆净盈利排序 = 盈利百分比/有效杠杆(如果亏损，未实现亏损≤0)
 * 盈利百分比 = (ADL执行价格持仓仓位价值 - 平均开仓价格仓位价值) / 平均开仓价格仓位价值
 * 有效杠杆 = ADL执行价格持仓仓位价值/（ADL执行价格持仓仓位价值 - 该仓位破产价格仓位价值）
 * @param positionType
 * @param markPriceValue 所谓执行价格仓位价值
 * @param openPriceValue 平均开仓价格仓位价值
 * @param bankruptValue 破产价格仓位价值
 */
export function calPerLeverProfit(
  positionType: number,
  markPriceValue: Decimal,
  openPriceValue: Decimal,
  bankruptValue: Decimal
): Decimal {
  let profitable =
    (positionType === HoldSideEnum.LONG_POSITIONS && openPriceValue.greaterThan(markPriceValue)) ||
    (positionType === HoldSideEnum.SHORT_POSITIONS && markPriceValue.greaterThan(openPriceValue)); // 是否盈利

  return profitable
    ? markPriceValue
        .sub(openPriceValue)
        .times(markPriceValue)
        .div(openPriceValue.times(markPriceValue.sub(bankruptValue)))
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_HALF_UP)
    : markPriceValue
        .sub(openPriceValue)
        .times(markPriceValue.sub(bankruptValue))
        .div(openPriceValue.times(markPriceValue))
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_HALF_UP);
}

/**
 * 传入不同的价格来计算不同的价值 **
 * @param count
 * @param price
 * @param isReverse
 * @param upDirection
 */
export function calValue(
  count: Decimal,
  price: Decimal,
  isReverse: boolean = true,
  upDirection: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calValue(count, price, upDirection)
    : forwardContractCalculator.calValue(count, price, upDirection);
}

/**
 * 计算当前持仓的减仓价 **
 * @param positionType
 * @param marginCount
 * @param averageOpenPrice
 * @param holdCount
 * @param keepMarginRate
 * @param fundsRate
 * @param isReverse
 * @return {Decimal}
 */
export function calReducePrice(
  positionType: UnionPositionType,
  marginCount: Decimal,
  averageOpenPrice: Decimal,
  holdCount: Decimal,
  keepMarginRate: Decimal,
  fundsRate: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calReducePrice(
      positionType,
      marginCount,
      averageOpenPrice,
      holdCount,
      keepMarginRate,
      fundsRate
    )
    : forwardContractCalculator.calReducePrice(
      positionType,
      marginCount,
      averageOpenPrice,
      holdCount,
      keepMarginRate,
      fundsRate
    );
}

/**
 * 计算最高买价（向上取整） **
 * @param limitPriceFlag 1 取合理标记价格计算 2 取市场价格计算 3 取范围更大的值
 * @param marketPrice 市场价格
 * @param fairPrice 合理标记价格
 * @param buyLimitPriceRatio 买价上浮比例
 * @param indexPrice 2019-10-30 新增 指数价格
 */
export function calMaxBuyPrice(
  limitPriceFlag: number,
  marketPrice: Decimal,
  fairPrice: Decimal,
  buyLimitPriceRatio: Decimal,
  indexPrice: Decimal
): Decimal {
  let calPrice;
  switch (limitPriceFlag) {
    case 1:
      calPrice = indexPrice.isZero() ? fairPrice : indexPrice;
      break;
    case 2:
      calPrice = marketPrice.isZero() ? fairPrice : marketPrice;
      break;
    case 3:
    default:
      calPrice = Decimal.max(fairPrice, marketPrice, indexPrice);
  }
  return calPrice.times(new Decimal(1).add(buyLimitPriceRatio));
}

/**
 * 计算最低卖价（向下取证） **
 * @param limitPriceFlag
 * @param marketPrice
 * @param fairPrice
 * @param sellLimitPriceRatio
 * @param indexPrice 2019-10-30 新增 指数价格
 */
export function calMinSellPrice(
  limitPriceFlag: number,
  marketPrice: Decimal,
  fairPrice: Decimal,
  sellLimitPriceRatio: Decimal,
  indexPrice: Decimal
): Decimal {
  let calPrice;
  switch (limitPriceFlag) {
    case 1:
      calPrice = indexPrice.isZero() ? fairPrice : indexPrice;
      break;
    case 2:
      calPrice = marketPrice.isZero() ? fairPrice : marketPrice;
      break;
    case 3:
    default:
      calPrice = Decimal.min(fairPrice, marketPrice, indexPrice.isZero() ? fairPrice : indexPrice);
  }
  return calPrice.times(new Decimal(1).sub(sellLimitPriceRatio));
}

/**
 * 根据收益计算平仓价格
 * @param positionType 仓位类型
 * @param count 张数
 * @param openPrice 开仓价格
 * @param profits 想要的收益
 * @param isReverse 正反合约
 */
export function calClosePrice(
  positionType: UnionPositionType,
  count: Decimal,
  openPrice: Decimal,
  profits: Decimal,
  isReverse: boolean = true
): Decimal {
  return isReverse
    ? reverseContractCalculator.calClosePrice(positionType, count, openPrice, profits)
    : forwardContractCalculator.calClosePrice(positionType, count, openPrice, profits);
}

/**
 * 计算单位杠杆净盈利排序
 * 全仓模式<br/><p/>
 * ADL实际盈利百分比＝[ADL执行价格净持仓仓位价值－（多头开仓价值－空头开仓价值）]/abs(多头开仓价值－空头开仓价值)<br/>
 * ADL有效杠杆＝ADL执行价格净持仓仓位价值/（ADL执行价格净持仓仓位价值 -该合约破产价格净持仓仓位价值）<br/>
 * 单位杠杆净盈利排序 = 盈利百分比*有效杠杆(如果盈利，未实现盈亏＞0) <br/>
 * 单位杠杆净盈利排序 = 盈利百分比/有效杠杆(如果亏损，未实现盈亏≤0) <br/>
 *
 * <p/>
 * 逐仓模式<br/><p/>
 * 单位杠杆净盈利排序 = 盈利百分比*有效杠杆(如果盈利，未实现盈亏＞0) <br/>
 * 单位杠杆净盈利排序 = 盈利百分比/有效杠杆(如果亏损，未实现亏损≤0) <br/>
 * 盈利百分比 = (ADL执行价格持仓仓位价值 - 平均开仓价格仓位价值) / 平均开仓价格仓位价值 <br/>
 * 有效杠杆 = ADL执行价格持仓仓位价值/（ADL执行价格持仓仓位价值 - 该仓位破产价格仓位价值） <br/>
 */
export function calPerLeverProfitICM(
  bankruptcyPrice: Decimal,
  netTotalHoldCount: Decimal,
  longPV: Decimal,
  shortPV: Decimal,
  upnl: Decimal,
  fairPrice: Decimal,
  isReverse: boolean
) {
  if (netTotalHoldCount.isZero()) {
    return new Decimal(0);
  }

  let executePV = calValue(netTotalHoldCount, fairPrice, isReverse, true);

  let bankruptcyPV = calValue(netTotalHoldCount, bankruptcyPrice, isReverse, true);

  let lsDistance = longPV.sub(shortPV);

  let effectiveLeverage = executePV
    .div(executePV.sub(bankruptcyPV))
    .toDP(12, Decimal.ROUND_HALF_UP)
    .abs();
  let profitPercents = executePV
    .sub(lsDistance)
    .div(lsDistance.abs())
    .toDP(12, Decimal.ROUND_HALF_UP);

  if (isReverse) {
    profitPercents = lsDistance
      .sub(executePV)
      .div(lsDistance.abs())
      .toDP(12, Decimal.ROUND_HALF_UP);
  }

  // console.log("calPerLeverProfitICM: ",
  //     "执行价仓位价值：", executePV.toString(),
  //     "多仓价值：", longPV.toString(),
  //     "空仓价值", shortPV.toString(),
  //     "仓位价值差", lsDistance.toString(),
  //     "upnl", upnl.comparedTo(D_ZERO),
  //     "有效杠杆：", effectiveLeverage.toString(),
  //     "盈利百分比：", profitPercents.toString())

  let profitable = profitPercents.comparedTo(D_ZERO) > 0;
  let profitInPerLever;
  if (profitable) {
    profitInPerLever = profitPercents
      .times(effectiveLeverage)
      .toDP(SERVER_CAL_PLACE, Decimal.ROUND_HALF_UP);
  } else {
    profitInPerLever = profitPercents
      .div(effectiveLeverage)
      .toDP(SERVER_CAL_PLACE, Decimal.ROUND_HALF_UP);
  }

  return profitInPerLever;
}

/**
 * 计算某个帐户全仓模式下的风险价格。在全仓模式下，风险价格是针对某一个具体帐户的具体币对的（不考虑帐户打通的情况下）。
 *正向某合约减仓价格＝
 * [当前总资产余额－该合约当前冻结成本*用户设置杠杆倍数*(该合约当前档位维持保证金率+ABS(手续费率))－该合约多头开仓价值+该合约空头开仓价值）]
 * /[该合约面值*(该合约总持仓(该合约当前档位维持保证金率+ABS(手续费率))－该合约净持仓)]
 *反向合约减仓价格＝
 * 面值*[总持仓*(维持保证金率+ABS(手续费率))+净持仓]
 * /[当前总资产余额+（多头开仓价值－空头开仓价值）-固定冻结开仓成本*设置杠杆倍数*(维持保证金率+ABS(手续费率))]
 */
export function calRiskPriceICM(
  totalBalance: Decimal,
  longOpenPricePV: Decimal,
  shortOpenPricePV: Decimal,
  capitalFee: Decimal,
  keepMarginRate: Decimal,
  totalHoldCount: Decimal,
  netHoldCount: Decimal,
  freezeValue: Decimal,
  isReverse: boolean = true
) {
  let marginRate = keepMarginRate;
  let totalBalancePart = totalBalance.sub(capitalFee);

  let result; // 计算结果

  if (isReverse) {
    let partOne = totalBalancePart
      .add(longOpenPricePV)
      .sub(shortOpenPricePV)
      .sub(freezeValue.times(marginRate));
    let partTwo = (totalHoldCount.times(marginRate).add(netHoldCount))
      .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);

    result = partTwo.div(partOne).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
  } else {
    let partOne = totalBalancePart
      .sub(longOpenPricePV)
      .add(shortOpenPricePV)
      .sub(freezeValue.times(marginRate));
    let partTwo = (totalHoldCount.times(marginRate).sub(netHoldCount))
      .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);

    result = partOne.div(partTwo).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
  }

  // 对于风险价格计算出来为负值时，为方便后续处理与计算，转换成满足意义要求的正值
  if (result.comparedTo(D_ZERO) <= 0) {
    let compareResult = netHoldCount.comparedTo(D_ZERO);
    if (compareResult > 0) {
      // 净多仓 无法爆仓取最小值
      result = new Decimal('0.000000000001');
    } else if (compareResult < 0) {
      // 净空仓 无法爆仓取最大值
      result = new Decimal('99999999999.99');
    } else {
      // 相当于没有持仓
      result = new Decimal(0);
    }
  }

  return result;
}

/**
 *  计算一个全仓帐户的帐户权益，或者帐户排除UPNL之外的总资产余额
 *      Equity = AVAILABLE + LOCK + LONG-MARGIN + SHORT-MARGIN + LONG-UPNL + SHORT-UPNL
 */
export function calEquityICM(
  balance: Decimal,
  freeze: Decimal,
  longMargin: Decimal,
  shortMargin: Decimal,
  longUPNL: Decimal,
  shortUPNL: Decimal,
  includedUPNL: boolean = true
) {
  let totalBalance = balance
    .add(freeze)
    .add(longMargin)
    .add(shortMargin);

  if (includedUPNL) {
    return totalBalance.add(longUPNL).add(shortUPNL);
  } else {
    return totalBalance;
  }
}

/**
 * 全仓模式计算委托部分对应价值
 * @param freeze
 * @param leverage
 * @return {Decimal}
 */
export function calDelegatePVICM(freeze: Decimal, leverage: Decimal) {
  return freeze.times(leverage).toDP(SERVER_CAL_PLACE);
}

/**
 * 计算全仓模式资金费用情况，使用净持仓
 * @param longOpenPrice
 * @param shortOpenPrice
 * @param capitalRate
 * @param netPosition
 * @param isReverse
 * @return {Decimal}
 */
export function calRealCapitalFeeICM(
  longOpenPrice: Decimal,
  shortOpenPrice: Decimal,
  capitalRate: Decimal,
  netPosition: Decimal,
  isReverse: boolean = true
) {
  // 反向合约是除以价格
  if (isReverse) {
    longOpenPrice = longOpenPrice.isZero()
      ? D_ZERO
      : D_ONE.div(longOpenPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
    shortOpenPrice = shortOpenPrice.isZero()
      ? D_ZERO
      : D_ONE.div(shortOpenPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
  }

  let longPart = Decimal.max(D_ZERO, capitalRate)
    .times(netPosition.times(longOpenPrice))
    .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
  let shortPart = Decimal.min(D_ZERO, capitalRate)
    .times(netPosition.times(shortOpenPrice))
    .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);

  return longPart.add(shortPart);
}

/**
 * 计算净持仓量：多仓（持仓+冻结？）-空仓（持仓+冻结？）
 * @param longHoldCount
 * @param shortHoldCount
 * @param longFreezeCount
 * @param shortFreezeCount
 * @param isTotal 是否考虑冻结
 * @return {Decimal}
 */
export function calNetHoldCount(
  longHoldCount: Decimal,
  shortHoldCount: Decimal,
  longFreezeCount: Decimal,
  shortFreezeCount: Decimal,
  isTotal: boolean
) {
  if (isTotal) {
    return longHoldCount.add(longFreezeCount).sub(shortHoldCount.add(shortFreezeCount));
  } else {
    return longHoldCount.sub(shortHoldCount);
  }
}
