<template>
    <transition name="errorfade">
          <div :class="[className?className:'error']" v-show="error" >{{error}}</div>
    </transition>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
     error:[String],
     className:[String]
    }
    
}
</script>
<style lang="less">
      .errorfade-enter-active, .errorfade-leave-active {
        transition: all .5s;
      }
      .errorfade-enter, .errorfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(-50%);
      }
</style>


