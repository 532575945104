<template>
  <div id="popup_outer">
    <div class="background"/>
    <div class="pop_container" v-on:click.stop="gotoLink(currentPopUpIndex)">
      <div class="pop_wrapper">
        <img class="close" id="close_id" v-on:click.stop="closePopUp()" src="~assets/img/popup/close.svg"/>

        <div class="popup_text_area" v-if="popupTitle[currentPopUpIndex] && popupContent[currentPopUpIndex]">
          <!-- <div class="popup_text_area"> -->
          <div class="popup_title">
            {{ popupTitle[currentPopUpIndex] }}
          </div>
          <div class="popup_text" @click="gotoLink(currentPopUpIndex)">
            <!--                        {{popupContent[currentPopUpIndex]}}<br>-->
            <div v-html="htmlDecodeByRegExp(popupContent[currentPopUpIndex])"></div>
            <!-- Jeff和Leslie確認，點擊文字外置跳轉(開新的頁面)，與原型稿需求有差異 -->
            <!-- </div> -->
          </div>
        </div>

        <div v-else class="popup-img">
          <img :src="popupImg[currentPopUpIndex]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import popUp from "~/server/common/index.js";
import {PopupClick, PopupExposure} from "../utils/sensorsEvent";

export default {
  data() {
    return {
      currentPopUpIndex: 0,
    };
  },
  props: {
    popupImg: {
      type: Array,
      default: () => []
    },
    popupLink: {
      type: Array,
      default: () => []
    },
    popupTitle: {
      type: Array,
      default: () => []
    },
    popupContent: {
      type: Array,
      default: () => []
    },
    popupIds: {
      type: Array,
      default: () => []
    },
    popupOriginalTitle: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    // this.popUp();
    // console.log("popupImg",this.popupImg);
    document.querySelector("html").style.overflow = "hidden";
  },
  destroyed() {
    document.querySelector("html").style.overflow = "visible";
  },
  watch: {
    currentPopUpIndex: {
      immediate: true,
      handler(val){
        // 监听currentPopUpIndex变化，如果当前index有ID值则上报神策
        if(this.popupIds[val]){
					PopupExposure( {commonName: this.popupOriginalTitle[val], popup_id: this.popupIds[val]})
        }
      }
    }
  },
  methods: {
    closePopUp() {
			PopupClick({
        commonName: this.popupOriginalTitle[this.currentPopUpIndex],
        popup_id: this.popupIds[this.currentPopUpIndex],
        click_action: 'Close'
      })
      this.currentPopUpIndex++;
      console.log(this.currentPopUpIndex,this.popupImg.length)
      if (this.currentPopUpIndex == this.popupImg.length) {
        document.getElementById("popup_outer").style.display = "none";
        document.querySelector("html").style.overflow = "visible";
      }
      if (document.getElementById("close_id").checked) {
        stopPropagation();
      }
    },
    gotoLink(currentPopUpIndex) {

			PopupClick({
        commonName: this.popupOriginalTitle[currentPopUpIndex],
        popup_id: this.popupIds[currentPopUpIndex],
        click_action: 'GoTo'
      })
      let href = this.popupLink[currentPopUpIndex];
      if (href && href.indexOf('https') < 0 && href.indexOf('http') < 0) href = "https://" + href;
      window.open(href);
    },
    // html 转义
    htmlDecodeByRegExp(str) {
      var s = "";
      if (str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "\\'");
      s = s.replace(/&quot;/g, "\"");
      return s;
    }
  }
  // }
}
</script>

<style lang="less" scoped>
.background {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 99998;
}

.pop_container {
  box-sizing: border-box;
  position: fixed;
  border-radius: 8px;
  //border-radius: 8px 8px 25px 25px;
  max-width: 510px;
  //height: 650px;
  //min-height: 650px;
  top: 50%;
  left: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  color: red;
  z-index: 99999;
  box-shadow: 0px 22px 30px 0px rgba(0, 0, 0, 0.12);
  // border: 1px solid saddlebrown;
  cursor: pointer;

  .popup-img {
    img {
      max-width: 510px;
      border-radius: 8px;
      display: block;
      max-height: 85vh;
    }
  }

  .close {
    position: absolute;
    color: @text-black;
    cursor: pointer;
    width: 32px;
    height: 32px;
    //right: -44px;
    //top: -44px;
    bottom: -64px;
    left: calc(50% - 16px);
  }

  .pop_wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .popup_text_area {
      //position: absolute;
      bottom: 0;
      width: 510px;
      height: 380px;
      background: #FFFFFF;
      border-radius: 8px;
      // min-height: 410px;
      //background: url(../../client/assets/img/popup/popup_layer.png);
      //background-size: 100%;
      cursor: default;
      // backdrop-filter: blur(8px);
      .popup_title {
        position: absolute;
        text-align: center;
        color: @text-black;
        font-size: 20px;
        .regular-font();
        font-weight: 600;
        color: @text-black;
        height: 28px;
        line-height: 28px;
        top: 32px;
        left: 50%;
        transform: translate(-50%);
        // border: 1px solid saddlebrown;
      }

      .popup_text {
        position: absolute;
        width: 452px;
        //text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: @text-black;
        line-height: 30px;
        top: 82px;
        left: 50%;
        transform: translate(-50%);
        // border: 1px solid blue;
        cursor: pointer;
        overflow: auto;
        height: 262px;
      }
    }
  }
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.popup_text::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
.popup_text::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
.popup_text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
