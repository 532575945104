<template>
  <!-- 加入战队 -->
  <client-only>
    <div>
      <!-- 加入弹窗 封闭和开放用同一个 -->
      <el-dialog :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" custom-class="createTeam-dialog-box" title="" :width="'440px'" center :visible.sync="dialogShow">
        <div class="team-dialog-box">
          <div class="team-dialog-close" @click="dialogShow=false"><i class="iconfont icon-close2"></i></div>
          <!-- 封闭型 -->
          <template v-if="type==1">
            <div class="team-dialog-title">{{$t('activity_challenge.join_team')}}</div>
            <div class="team-form-box">
              <div class="team-form-item">
                <label>{{$t('activity_challenge.invite_code')}}</label>
                <input type="text" v-model="iptCode">
                <div class="team-form-err">
                  <v-errtip :error="codeErr"></v-errtip>
                </div>
              </div>
              <div class="team-form-text">{{$t('activity_challenge.join_team_tip2')}}</div>
              <div class="team-form-text">{{$t('activity_challenge.join_team_tip3')}}</div>
            </div>
          </template>

          <!-- 开放型 -->
          <template v-else>
            <img src="~/assets/img/challenge/captain-success.png" alt="">
            <div class="team-succ-text">안내</div>
            <div class="team-succ-subtext">{{$t('activity_challenge.join_team_tip')}}</div>
          </template>
          <div class="team-form-btn" :class="{center: type==2}">
            <challenge-btn :disabled="joinTeamDisabled" :text="$t('activity_challenge.join_team')" @click="joinTeamFn"></challenge-btn>
          </div>
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>

      <!-- 加入成功 -->
      <el-dialog :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" custom-class="createTeam-dialog-box" title="" :width="'440px'" center :visible.sync="dialogSuccShow">
        <div class="team-dialog-box">
          <div class="team-dialog-close" @click="dialogSuccShow=false"><i class="iconfont icon-close2"></i></div>
          <img src="~/assets/img/challenge/tip.png" alt="">
          <div class="team-succ-text">{{$t('activity_challenge.join_success')}}</div>
          <div class="team-form-btn center">
            <challenge-btn :text="$t('activity_challenge.btn_confirm')" @click="dialogSuccShow=false"></challenge-btn>
          </div>
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>

      <!-- 不允许加入 -->
      <el-dialog :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" custom-class="createTeam-dialog-box" title="" :width="'440px'" center :visible.sync="dialogFailShow">
        <div class="team-dialog-box">
          <div class="team-dialog-close" @click="dialogFailShow=false"><i class="iconfont icon-close2"></i></div>
          <img src="~/assets/img/challenge/must-login.png" alt="">
          <div class="team-succ-subtext">{{$t('activity_challenge.join_team_fail')}}</div>
          <div class="team-form-btn center">
            <challenge-btn :text="$t('activity_challenge.btn_confirm')" @click="dialogFailShow=false"></challenge-btn>
          </div>
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
  </client-only>
</template>

<script>
import challengeBtn from '@/components/challenge/challengeBtn'
import { teamJoin } from '~/server/activity/challenge.js'
export default {
  props: {
    show: Boolean,
    can: Boolean, // 是否可加入
    type: Number, // 战队类型：1：封闭型；2：开放型
    id: [String, Number] // 战队ID
  },
  components: {
    challengeBtn
  },
  data() {
    return {
      dialogShow: false,
      dialogSuccShow: false,
      dialogFailShow: false,
      iptCode: '',
      codeErr: '',
      joinTeamDisabled: false,
    }
  },
  watch: {
    show: {
      handler(val) {
        this.iptCode = '';
        this.codeErr = '';
        if (val) {
          if(this.can) {
            this.dialogShow = val;
          } else {
            this.dialogFailShow = val;
          }
        }
      },
      immediate: true
    },
    dialogShow(val) {
      if(!val) {
        this.$emit("update:show", false);
      }
    },
    dialogFailShow(val) {
      if(!val) {
        this.$emit("update:show", false);
      }
    },
    dialogSuccShow(val) {
      if(!val) {
        this.$emit("update:show", false);
        this.$emit("success");
      }
    },
  },
  methods: {
    joinTeamFn() {
      let params = {
        activityId: 1,
        teamId: this.id,
      };
      if(this.type == 1) {
        params.inviteCode = this.iptCode

        if(!this.iptCode) {
          this.codeErr = this.$t('activity_challenge.join_code_required');
          return;
        }
        this.codeErr = '';
      }
      this.joinTeamDisabled = true;
      teamJoin(params).then(res => {
        if(res.code === '00000') {
          this.dialogShow = false;
          this.dialogSuccShow = true;
        } else {
          this.joinTeamDisabled = false;
          if(res.msg) {
            if(this.type == 1) {
              this.codeErr = res.msg;
            } else {
              BT.util.error(res.msg);
            }
          }
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>
// 加入战队 表单 弹窗
.createTeam-dialog-box{
  .team-dialog-box{
    position: relative;
    margin: -96px -25px -61px;
    padding: 40px 40px 32px;
    background: #3F3F4C;
    .team-dialog-close{
      position: absolute;
      top: 20px;
      right: 18px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
    .team-dialog-title{
      font-size: 24px;
      color: #FFFFFF;
    }
    .team-form-box{
      margin-top: 6px;
      .team-form-item{
        width: 360px;
        label{
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 14px;
          color: #D0D1D4;
          &.tip{
            font-size: 12px;
          }
        }
        input{
          display: block;
          width: 332px;
          margin-top: 5px;
          padding: 10px 14px;
          color: #fff;
          font-size: 14px;
          border: none;
          background-color: #2F2F38;
        }
        .team-form-err{
          margin-top: 6px;
          font-size: 12px;
          color: #FFDB0F;
        }
      }
      .team-form-text{
        font-size: 12px;
        color: #D0D1D4;
        line-height: 22px;
        text-align: left;
        &:first-child{
          margin-top: 24px;
        }
      }
    }
    .team-succ-subtext{
      margin-top: 30px;
      text-align: center;
      font-size: 14px;
      color: #fff;
    }
    img{
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
    .team-succ-text{
      margin: 30px auto 0;
      text-align: center;
      font-size: 23px;
      color: #fff;
    }
    .team-form-btn{
      margin-top: 24px;
      text-align: right;

      &.center{
        text-align: center;
      }
    }
  }
}

</style>
