<template>
  <!-- 战队列表 -->
  <div class="team-list-box">
    <div class="team-list-search clearfix">
      <div>{{$t('activity_challenge.team_list_title')}}</div>
      <div class="search-input">
        <i class="iconfont icon-heyuedasai-sousuo"></i>
        <input type="text" v-model="searchStr" :placeholder="$t('activity_challenge.search_team')">
      </div>
    </div>
    <ul class="team-list" v-loading="loading" element-loading-background="#34343E">
      <template v-if="filterList.length">
        <li v-for="(item, index) in filterList" :key="index" class="clearfix">
          <div class="rank-icon" :class="[item.rank < 4 ? 'top_' + item.rank : '']">{{item.rank > 3 ? item.rank : ''}}</div>
          <div class="avatar-box" @click="showTeamInfo(item.id)">
            <img :src="item.logo" alt="">
          </div>
          <div class="team-info">
            <span class="team-info-type">{{item.teamName || '- -'}}<i v-if="item.type == 1" class="team-list-type">{{$t('activity_challenge.team_list_type')}}</i></span>
            <span>{{item.declaration || '- -'}}</span>
          </div>
          <div>
            <span>{{$t('activity_challenge.team_list_captain')}}</span><span>{{item.captainName || '- -'}}</span>
          </div>
          <div>
            <span>{{$t('activity_challenge.team_list_memeber')}}</span><span>{{item.count || 0}}</span>
          </div>
          <div>
            <span>{{$t('activity_challenge.team_list_total')}}</span><span>{{item.total || '- -'}}</span>
          </div>
          <div>
            <challenge-btn :gray="!can" @click="joinTeamFn(item)" :text="$t('activity_challenge.join_team')"></challenge-btn>
          </div>
        </li>
        <li class="list-more">
          <div><i v-show="isMore" @click="showMore" class="iconfont icon-heyuedasai-xiala"></i></div>
        </li>
      </template>
      <template v-else>
        <no-challenge-data :text="noDataText"></no-challenge-data>
      </template>
    </ul>

    <!-- 加入战队弹窗 -->
    <join-team :show.sync="joinTeamDialog" :can="can" :type="teamType" :id="teamId" @success="joinSuccessFn"></join-team>

    <!-- 先去登录 -->
    <go-login :show.sync="loginDialogShow"></go-login>

    <!-- 再去实名 -->
    <go-real-vali :show.sync="realValiDialogShow"></go-real-vali>

  </div>
</template>

<script>
import joinTeam from '@/components/challenge/joinTeam'
import { teamSignUp, teamJoin, teamList, teamMembers, teamInfo } from '~/server/activity/challenge.js'
import challengeBtn from '@/components/challenge/challengeBtn'
import goLogin from '@/components/challenge/goLogin'
import goRealVali from '@/components/challenge/goRealVali'
import noChallengeData from '@/components/challenge/noChallengeData'
import { mapGetters, mapState } from 'vuex'
export default {
  props: {
    can: Boolean, // 是否可加入战队
  },
  components: {
    challengeBtn,
    joinTeam,
    noChallengeData,
    goLogin,
    goRealVali,
  },
  data() {
    return {
      searchStr: '',
      list: [],
      joinTeamDialog: false,
      teamType: -1,
      teamId: '',
      isMore: false,
      moreList: [],
      loading: true,
      loginDialogShow: false,
      realValiDialogShow: false,
      noDataText: '',
    }
  },
  computed: {
    ...mapGetters(['isLogin']),
    ...mapState(['user']),
    filterList() {
      if(this.searchStr) {
        return this.list;
      } else{
        return this.moreList;
      }
    },
  },
  mounted() {
    this.getTeamList();
  },
  watch: {
    searchStr(val) {
      if(!val) {
        this.isMore = true;
        this.noDataText = '데이터가 없음';
      }
      this.getTeamList(val);
    }
  },
  methods: {
    getTeamList(str) {
      let params = {
        activityId: 1,
      };
      if(str) {
        params.teamName = str;
      }
      teamList(params).then(res => {
        if(res.code == '00000') {
          this.list = res.data || [];
          if(!str) {
            this.moreList = this.list.slice(0, 10);
            if(this.list.length > 10) {
              this.isMore = true;
            }
          } else {
            this.moreList = this.list;
            this.isMore = false;
            this.noDataText = this.$t('activity_challenge.no_team_text');
          }
          this.loading = false;
        }
      });
    },

    // 加入成功
    joinSuccessFn() {
      this.$emit('joinSuccess');
    },

    joinTeamFn(item) {
      if(!this.isLogin) {
        this.loginDialogShow = true;
        return;
      }
      if(!this.user.hasRealValidate) {
        this.realValiDialogShow = true;
        return;
      }
      if(!this.can) {
        return;
      }
      this.teamId = item.id;
      this.teamType = item.type;
      this.joinTeamDialog = true;
    },
    showMore() {
      this.isMore = false;
      this.moreList = this.list;
    },
    showTeamInfo(id) {
      this.$router.push(
        {path: this.$i18n.path(`challenging/member?id=${id}#elMember`)}
      );
    }
  },
}
</script>

<style lang="less" scoped>
.team-list-box{
  margin-top: 45px;
  .team-list-search{
    width: 1020px;
    margin: 0 auto;
    div{
      float: left;
      font-size: 20px;
      color: #fff;
      &.search-input{
        float: right;
        position: relative;
      }
      .icon-heyuedasai-sousuo{
        position: absolute;
        top: 8px;
        left: 11px;
        z-index: 2;
        font-size: 14px;
      }
      input{
        display: inline-block;
        width: 134px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        padding: 7px 7px 7px 30px;
        border: 1px solid #979797;
        background: transparent;
        transition: all .35s;

        &:focus{
          width: 300px;
          transition: all .35s;
        }
      }
    }
  }
  .team-list{
    width: 1020px;
    margin: 30px auto 0;
    li{
      position: relative;
      margin-bottom: 26px;
      padding: 32px 16px;
      background: #34343E;
      font-size: 14px;
      display: flex;
      align-items: center;

      div{
        float: left;
        width: 160px;
        margin: 0 auto;
        text-align: center;
        color: #fff;

        &.team-info{
          position: relative;
          top: -4px;
          left: -2px;
          width: 267px;
          height: 50px;
          padding-top: 8px;
          text-align: left;
          padding-left: 12px;
          font-size: 12px;
          background-image: linear-gradient(270deg, rgba(131,131,159,0.00) 9%, #7D7D92 100%);
          span{
            width: 216px;
            text-align: left;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;

            &.team-info-type{
              display: flex;
              align-items: center;
              margin-bottom: 3px;
              color: #fff;
              font-size: 14px;
            }
          }
        }
        &.avatar-box{
          width: 66px;
          height: 66px;
          text-align: center;
          background: url('~assets/img/challenge/avatar-list.svg') bottom right no-repeat;
          cursor: pointer;
          img{
            display: inline-block;
            width: 50px;
            height: 50px;
            margin-top: 8px;
          }
        }
        &.rank-icon{
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          background: #7E7E95;

          &.top_1{
            left: -6px;
            top: -6px;
            width: 28px;
            height: 32px;
            background: url('~assets/img/challenge/champion.svg') center center no-repeat;
            background-size: contain;
          }
          &.top_2{
            left: -6px;
            top: -6px;
            width: 28px;
            height: 32px;
            background: url('~assets/img/challenge/runnerup.svg') center center no-repeat;
            background-size: contain;
          }
          &.top_3{
            left: -6px;
            top: -6px;
            width: 28px;
            height: 32px;
            background: url('~assets/img/challenge/thirdplace.svg') center center no-repeat;
            background-size: contain;
          }
        }
        span{
          display: block;
          text-align: center;
          &:first-child{
            margin-bottom: 14px;
            color: #BEBEBE;
          }
        }
      }

      &.list-more{
        margin-top: -6px;
        background: none;
        div{
          float: auto;
          width: 100%;
        }
        .icon-heyuedasai-xiala{
          font-size: 36px;
          color: #979797;
          cursor: pointer;
        }
      }

      .team-list-type{
        display: inline-block;
        margin-left: 3px;
        padding: 2px 9px;
        font-style: normal;
        background: #FA2B25;
        font-size: 12px;
        border-radius: 18px;
        transform: scale(.8);
      }
    }
  }
}
</style>
