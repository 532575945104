<template>
  <div class="challenge-role-box">
    <h3 class="role-h3">{{$t('activity_challenge.rule_title')}}</h3>
    <dl class="challenge-role-list">
      <dt @click="showText(0)">{{$t('activity_challenge.rule_time_1')}}<span class="rule-arrow" :class="{rotate: textIndex0}"><img src="~assets/img/challenge/rule-arrow.svg" alt=""></span></dt>
      <dd v-show="textIndex0">
          <table class="rule-table" border="1" colspan="0" rowspan="0">
          <tr>
            <td>{{$t('activity_challenge.rule_time_td_1')}}</td>
            <td>{{$t('activity_challenge.rule_time_td_2')}}</td>
          </tr>
          <tr>
            <td>{{$t('activity_challenge.rule_time_1_1')}}</td>
            <td>{{dateFormat(list.captainBegin, 'MM.dd')}}-{{dateFormat(list.captainEnd, 'MM.dd')}}</td>
          </tr>
          <tr>
            <td>{{$t('activity_challenge.rule_time_1_2')}}</td>
            <td>{{dateFormat(list.teamBegin, 'MM.dd')}}-{{dateFormat(list.teamEnd, 'MM.dd')}}</td>
          </tr>
          <tr>
            <td>{{$t('activity_challenge.rule_time_1_3')}}</td>
            <td>{{dateFormat(list.formalBegin, 'MM.dd')}}-{{dateFormat(list.formalEnd, 'MM.dd')}}</td>
          </tr>
          <tr>
            <td>{{$t('activity_challenge.rule_time_1_4')}}</td>
            <td>{{dateFormat(list.overBegin, 'MM.dd')}}-{{dateFormat(list.overEnd, 'MM.dd')}}</td>
          </tr>
        </table>
      </dd>
      <dt @click="showText(1)">{{$t('activity_challenge.rule_1')}}<span class="rule-arrow" :class="{rotate: textIndex1}"><img src="~assets/img/challenge/rule-arrow.svg" alt=""></span></dt>
      <dd v-show="textIndex1">
        <ul>
          <li>{{$t('activity_challenge.rule_1_1')}}</li>
          <li>{{$t('activity_challenge.rule_1_2')}}</li>
          <li>{{$t('activity_challenge.rule_1_3')}}</li>
          <li>{{$t('activity_challenge.rule_1_4')}}</li>
          <li>{{$t('activity_challenge.rule_1_5')}}</li>
          <li>{{$t('activity_challenge.rule_1_6')}}</li>
          <li>{{$t('activity_challenge.rule_1_7')}}</li>
        </ul>
      </dd>
      <dt @click="showText(2)">{{$t('activity_challenge.rule_2')}}<span class="rule-arrow" :class="{rotate: textIndex2}"><img src="~assets/img/challenge/rule-arrow.svg" alt=""></span></dt>
      <dd v-show="textIndex2">
        <ul>
          <li>{{$t('activity_challenge.rule_2_1')}}</li>
          <li>{{$t('activity_challenge.rule_2_2')}}</li>
          <li>{{$t('activity_challenge.rule_2_3')}}</li>
          <li>{{$t('activity_challenge.rule_2_4')}}</li>
          <li>{{$t('activity_challenge.rule_2_5')}}</li>
          <li>{{$t('activity_challenge.rule_2_6')}}</li>
          <li>{{$t('activity_challenge.rule_2_7')}}</li>
          <li>{{$t('activity_challenge.rule_2_8')}}</li>
        </ul>
      </dd>
      <dt @click="showText(3)">{{$t('activity_challenge.rule_3')}}<span class="rule-arrow" :class="{rotate: textIndex3}"><img src="~assets/img/challenge/rule-arrow.svg" alt=""></span></dt>
      <dd v-show="textIndex3">
        <ul>
          <li>{{$t('activity_challenge.rule_3_1')}}</li>
          <li>{{$t('activity_challenge.rule_3_2')}}</li>
          <li>{{$t('activity_challenge.rule_3_3')}}</li>
          <li>{{$t('activity_challenge.rule_3_4')}}</li>
          <li>{{$t('activity_challenge.rule_3_5')}}</li>
          <li>{{$t('activity_challenge.rule_4')}}</li>
        </ul>
        <table class="rule-table" border="1" colspan="0" rowspan="0">
          <tr>
            <td>{{$t('activity_challenge.rule_4_td_1')}}</td>
            <td>{{$t('activity_challenge.rule_4_td_2')}}</td>
            <td>{{$t('activity_challenge.rule_4_td_3')}}</td>
            <td>{{$t('activity_challenge.rule_4_td_4')}}</td>
          </tr>
          <tr>
            <td>1-300</td>
            <td>15,000U</td>
            <td>9,000U</td>
            <td>6,000U</td>
          </tr>
          <tr>
            <td>301-500</td>
            <td>30,000U</td>
            <td>18,000U</td>
            <td>12,000U</td>
          </tr>
          <tr>
            <td>501-600</td>
            <td>50,000U</td>
            <td>30,000U</td>
            <td>20,000U</td>
          </tr>
          <tr>
            <td>1001-2000</td>
            <td>100,000U</td>
            <td>60,000U</td>
            <td>40,000U</td>
          </tr>
        </table>
        <ul>
          <li>{{$t('activity_challenge.rule_4')}}</li>
          <li>{{$t('activity_challenge.rule_4_1')}}</li>
          <li>{{$t('activity_challenge.rule_4_2')}}</li>
          <li>{{$t('activity_challenge.rule_4_3')}}</li>
          <li>{{$t('activity_challenge.rule_4_4')}}</li>
          <li>{{$t('activity_challenge.rule_4_5')}}</li>
          <li>{{$t('activity_challenge.rule_4_6')}}</li>
          <li>{{$t('activity_challenge.rule_4_7')}}</li>
          <li>{{$t('activity_challenge.rule_4_8')}}</li>
          <li>{{$t('activity_challenge.rule_4_9')}}</li>
          <li>{{$t('activity_challenge.rule_4_10')}}</li>
          <li>{{$t('activity_challenge.rule_4_11')}}</li>
          <li>{{$t('activity_challenge.rule_4_12')}}</li>
          <li>{{$t('activity_challenge.rule_4_13')}}</li>
          <li>{{$t('activity_challenge.rule_4_14')}}</li>
          <li>{{$t('activity_challenge.rule_4_15')}}</li>
          <li>{{$t('activity_challenge.rule_4_16')}}</li>
        </ul>
      </dd>
      <dt @click="showText(4)">{{$t('activity_challenge.rule_4_17')}}<span class="rule-arrow" :class="{rotate: textIndex4}"><img src="~assets/img/challenge/rule-arrow.svg" alt=""></span></dt>
      <dd v-show="textIndex4">
        <ul>
          <li>{{$t('activity_challenge.rule_4_18')}}</li>
          <li>{{$t('activity_challenge.rule_4_19')}}</li>
          <li>{{$t('activity_challenge.rule_4_20')}}</li>
          <li>{{$t('activity_challenge.rule_4_21')}}</li>
          <li>{{$t('activity_challenge.rule_4_22')}}</li>
        </ul>
      </dd>
    </dl>
  </div>
</template>

<script>
import { formatDate } from "~/utils/utils.js";
export default {
  props: {
    list: Object,
    begin: [Number, String],
    end: [Number, String],
    rewardTime: [Number, String]
  },
  data() {
    return {
      textIndex0: true,
      textIndex1: true,
      textIndex2: true,
      textIndex3: true,
      textIndex4: true,
    }
  },
  methods: {
    showText(val) {
      this.$data['textIndex' + val] = !this.$data['textIndex' + val];
    },
    dateFormat(str, format) {
      return formatDate(str, format);
    }
  },
}
</script>

<style lang="less">
  .challenge-role-box{
    max-width: 1020px;
    margin: 40px auto 0;
    padding-bottom: 170px;
    .role-h3{
      font-size: 20px;
      color: #FFF;
    }
    .challenge-role-list{
      margin-top: 16px;
      color: #fff;
      dt{
        margin-top: 18px;
        padding: 13px 10px 11px 23px;
        font-size: 14px;
        background-color: #34343E;
        .rule-arrow{
          float: right;
          color: #1A1A1E;
          font-size: 14px;
          transition: all .35s;
          img{
            display: block;
            width: 18px;
            height: 18px;
          }
        }
      }
      dd{
        background-color: #34343E;
        margin-bottom: 18px;
        padding: 13px 10px 11px 23px;
        font-size: 12px;
        ul li {
          margin-bottom: 16px;
        }
      }

      .rotate{
        transition: all .35s;
        transform: rotate(90deg);
      }

      .rule-table{
        width: 50%;
        margin-bottom: 16px;
        border: 1px solid #fff;
        td {
          padding: 10px 16px;
          text-align: center;
        }
      }
    }
  }
</style>
