// @flow
opaque type TYPE_LONG_POSITIONS = 1;
opaque type TYPE_SHORT_POSITIONS = 2;

export type UnionPositionType = TYPE_LONG_POSITIONS | TYPE_SHORT_POSITIONS;

const HoldSideEnum = Object.freeze({
    LONG_POSITIONS: 1,
    SHORT_POSITIONS: 2
});

export {HoldSideEnum};

export const SERVER_CAL_PLACE = 12; // 服务器计算用精度
