<template>
  <!-- 个人排行榜 -->
  <div class="member-list-box" v-loading="loading" element-loading-background="#34343E">
    <div class="top-right-bg"></div>
    <div class="member-list-wrap">
      <ul class="member-list" >
        <li>
          <span>{{$t('activity_challenge.list_rank')}}</span>
          <span>{{$t('activity_challenge.personal_name')}}</span>
          <span>{{$t('activity_challenge.in_team')}}</span>
          <span>{{$t('activity_challenge.team_list_total')}}</span>
        </li>
        <li v-if="myInfo.name">
          <span>{{myInfo.rank}}</span>
          <span>{{myInfo.name}}<i class="its-me">{{$t('activity_challenge.its_me')}}</i></span>
          <span>{{myInfo.teamName}}</span>
          <span>{{myInfo.total}}</span>
        </li>
        <template v-if="moreList && moreList.length">
          <li v-for="(item, index) in moreList" :key="index">
            <span>
              <template v-if="index > 2">{{index + 1}}</template>
              <template v-else>
                <i class="rank-icon" :class="[index < 3 ? 'top_' + index : '']"></i>
              </template>
            </span>
            <span>{{item.name}}</span>
            <span>{{item.teamName}}</span>
            <span>{{item.total}}</span>
          </li>
        </template>
        <template v-else>
          <no-challenge-data></no-challenge-data>
        </template>
      </ul>
    </div>
    <div class="member-list-footer">
      <i v-if="isMore" class="iconfont icon-heyuedasai-xiala" @click="showMore"></i>
    </div>
  </div>
</template>

<script>
import {  personalRank, personalInfo } from '~/server/activity/challenge.js'
import noChallengeData from '@/components/challenge/noChallengeData'
import challengeBtn from '@/components/challenge/challengeBtn'
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    role: {
      type: [String, Number],
      default: 2 // 3: 普通用户， 2：队员， 1： 队长
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLogin'])
  },
  components: {
    challengeBtn,
    noChallengeData,
  },
  data() {
    return {
      isMore: false,
      myInfo: {
        rank: '- -', name: '', total: 0, signUpTime: ''
      },
      list: [],
      moreList: [],
      loading: true,
    }
  },
  mounted() {
    this.getList();
    window.isReadyPromise.then(()=>{
      this.getMyInfo();
    });
  },
  methods: {
    getList() {
      personalRank({activityId: 1}).then(res => {
        if(res.code === '00000') {
          this.list = res.data || [];
          this.moreList = this.list.slice(0, 10);
          if(this.list.length > 10) {
            this.isMore = true;
          }

          if(this.isLogin) {
            this.myInfo = res.data.user || {
              name: '',
              rank: '- -',
              total: 0,
            }
          }
          this.loading = false;
        }
      });
    },
    getMyInfo() {
      if(this.isLogin) {
        personalInfo({activityId: 1}).then(res => {
          if(res.code === '00000') {
            this.myInfo = {
              rank: res.data.personalRank || '- -',
              total: res.data.personalTotal || 0,
              name: res.data.name,
              signUpTime: res.data.signUpTime,
              teamName: res.data.teamName,
            };
          }
        });
      }
    },
    showMore() {
      this.isMore = false;
      this.moreList = this.list;
    },
  },
}
</script>

<style lang="less" scoped>
.member-list-box{
  position: relative;
  width: 1020px;
  margin: 0 auto;
  padding-top: 10px;
  background: linear-gradient(45deg, transparent 48px, #34343E 0);

  .top-right-bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 295px;
    height: 56px;
    background: url("~assets/img/challenge/list-topright-bg.png") center center no-repeat;
    background-size: auto 100%;
  }

  .member-list-wrap{
    margin: 0 10px;
    border: 1px solid #1A1A1E;
    border-bottom: none;

    .member-list{
      padding: 30px 0;
      li{
        display: flex;
        align-items: center;
        justify-self: center;
        height: 70px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        &:nth-of-type(even) {
          background: #3F3F4C;
        }
        span{
          flex: 1;
          &:nth-of-type(1){
            flex: 1;
          }
          &:nth-of-type(2){
            padding-left: 90px;
          }
          &:nth-of-type(3){
            padding-left: 60px;
          }
          &:nth-of-type(2), &:nth-of-type(3){
            text-align: left;
          }
          &:nth-of-type(4){
            padding-right: 60px;
            text-align: right;
          }
        }
        .its-me{
          display: inline-block;
          margin-left: 3px;
          padding: 2px 9px;
          font-style: normal;
          background: #FA2B25;
          font-size: 12px;
          line-height: 12px;
          border-radius: 18px;
          transform: scale(.8);
        }
        .rank-icon{
          display: inline-block;
          width: 28px;
          height: 32px;
          margin-top: 6px;

          &.top_0{
            background: url('~assets/img/challenge/champion.svg') center center no-repeat;
            background-size: contain;
          }
          &.top_1{
            background: url('~assets/img/challenge/runnerup.svg') center center no-repeat;
            background-size: contain;
          }
          &.top_2{
            background: url('~assets/img/challenge/thirdplace.svg') center center no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
  .member-list-footer{
    width: 1020px;
    height: 160px;
    text-align: center;
    background: url("~assets/img/challenge/memeber-footer-bg.svg") bottom right no-repeat;
    background-size: auto 100%;
    .icon-heyuedasai-xiala{
      font-size: 36px;
      color: #979797;
      cursor: pointer;
    }
  }
}
</style>
