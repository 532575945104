import { Decimal } from "decimal.js";

const Range = [
  { min: 0, max: 1000, sign: "" },
  { min: 1000, max: 1000000, sign: "K" },
  { min: 1000000, max: 1000000000, sign: "M" },
  { min: 1000000000, max: null, sign: "B" }
];

export function toShortNumber(value, digit) {
  if (!value || isNaN(value)) return value;
  let dValue = new Decimal(value);
  digit = digit || 2;
  let range =
    Range.find(e => {
      if (e.max) {
        return dValue.greaterThan(e.min) && dValue.lessThanOrEqualTo(e.max);
      } else {
        return dValue.greaterThan(e.min);
      }
    }) || {};

  let min = range.min,
    sign = range.sign;
  if (min === 0 || !min) {
    return value;
  }
  
  let big = dValue.div(min);

  return "".concat(big.toFixed(digit, Decimal.ROUND_FLOOR)).concat(sign);
}
