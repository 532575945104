<template>
  <div class="challenge-no-data">
    <img src="~/assets/img/challenge/captain-fail.png" alt="">
    <div>{{text || '데이터가 없음'}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.challenge-no-data{
  margin: 84px auto 0;
  text-align: center;
  color: #fff;
  font-size: 26px;
  img{
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto 40px;
  }
}
</style>
