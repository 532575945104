// @flow
/**
 * 反向合约公式
 */

// $flow-disable-line
import {Decimal} from "decimal.js";
import type {UnionPositionType} from "./CommonCalculator";
import {HoldSideEnum, SERVER_CAL_PLACE} from "./CommonCalculator";

export default {

  /**
   * 根据传入的价格可以计算各种价格下的仓位价值
   * 数量 / 价格
   * @param count
   * @param price
   * @param upDirection
   * @return {Decimal}
   */
  calValue(
    count: Decimal,
    price: Decimal,
    upDirection: boolean
  ): Decimal {
    if (price.isZero()) {
      return new Decimal(0);
    }
    return count.div(price).toDP(SERVER_CAL_PLACE, upDirection ? Decimal.ROUND_UP : Decimal.ROUND_DOWN);
  },

  /**
   * 计算未实现盈亏
   * 张数 * 面值 / 平均开仓价 - 张数 * 面值 / 成交价
   * 张数 * 面值 / 成交价 - 张数 * 面值 / 平均开仓价
   * @param positionType
   * @param volume
   * @param calPrice
   * @param averageOpenPrice
   * @return {Decimal|*}
   */
  getProfits(
    positionType: number,
    volume: Decimal,
    calPrice: Decimal,
    averageOpenPrice: Decimal
  ): Decimal {
    if (volume.isZero() || calPrice.isZero()) {
      return new Decimal(0);
    }
    let amount = volume;
    let compValue = averageOpenPrice.comparedTo(calPrice);
    let profits;
    // 如果开仓均价和合理标记价格相同，就不需要继续计算
    if (compValue === 0) {
      return new Decimal(0);
    }
    if (positionType === 1) { // 多仓
      // 多仓
      profits = amount.times(averageOpenPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_DOWN)
        .sub(amount.times(calPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP));
      if (compValue < 0) {
        profits = Decimal.max(profits, new Decimal(0));
      }
    } else {
      // 空仓
      profits = amount.times(calPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_DOWN)
        .sub(amount.times(averageOpenPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP));
      if (compValue > 0) {
        profits = Decimal.max(profits, new Decimal(0));
      }
    }
    return profits;
  },

  /**
   * 计算最大可开张数
   * 理论值：全部可用资产*平均开仓价格/{[（1+冻结保证金上浮比例）/当前杠杆+手续费率*（1+冻结手续费上浮比例）]*合约面值}
   * 限制值：杠杆对应档位的最大张数-委托张数-持仓张数
   * @param balance
   * @param calPrice
   * @param feeRate // 直接走配置，无法区分taker maker一律按taker计算
   * @param openCostUpRatio
   * @param openCostFeeUpRatio
   * @param userLeverage
   * @param gradientMaxCount
   * @param holdCount
   * @param delegateCount
   * @return {Decimal}
   */
  calMaxOpenContractCount(
    balance: Decimal,
    calPrice: Decimal,
    feeRate: Decimal,
    openCostUpRatio: Decimal,
    openCostFeeUpRatio: Decimal,
    userLeverage: Decimal,
    gradientMaxCount: Decimal,
    holdCount: Decimal,
    delegateCount: Decimal
  ): Decimal {
    if (userLeverage.isZero() || calPrice.isZero()) {
      return new Decimal(0);
    }

    let maxCount = gradientMaxCount.sub(holdCount).sub(delegateCount), balanceMax;

    balanceMax = balance.times(calPrice).div(
      openCostUpRatio.add(1).div(userLeverage).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP)
        .add(feeRate.times(openCostFeeUpRatio.add(1)))).toDP(0, Decimal.ROUND_DOWN);

    if (maxCount.isNaN()) {
      return new Decimal(0);
    } else {
      if (maxCount.isNegative()) {
        return new Decimal(0);
      } else {
        let r = Decimal.max(Decimal.min(balanceMax, maxCount), new Decimal(0));
        if (r.isNaN()) {
          return new Decimal(0);
        } else {
          return r;
        }
      }
    }
  },

  /**
   * 计算保证金量
   * @return 数量 / (价格 * 杠杆)
   * @param count
   * @param markPrice
   * @param leverage
   * @return {Decimal}
   */
  calMarginCount(
    count: Decimal,
    markPrice: Decimal,
    leverage: Decimal
  ): Decimal {
    if (markPrice.isZero() || leverage.isZero()) {
      return new Decimal(0);
    }
    let val = count.div(markPrice.times(leverage)).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
    return count.div(markPrice.times(leverage)).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
  },

  /**
   * 计算减仓价
   * @param positionType
   * @param marginCount
   * @param averageOpenPrice
   * @param holdCount
   * @param keepMarginRate
   * @param fundsRate
   * @return {Decimal}
   */
  calReducePrice(
    positionType: UnionPositionType,
    marginCount: Decimal,
    averageOpenPrice: Decimal,
    holdCount: Decimal,
    keepMarginRate: Decimal,
    fundsRate: Decimal,
  ): Decimal {
    let diffRate;
    if (positionType === HoldSideEnum.LONG_POSITIONS) {
      diffRate = keepMarginRate.add(Decimal.max(fundsRate, new Decimal(0)));
    } else {
      diffRate = keepMarginRate.add(Decimal.min(fundsRate, new Decimal(0)));
    }

    return this.calPrice(positionType, marginCount, averageOpenPrice, holdCount, diffRate, keepMarginRate);
  },

  /**
   * 算减仓价和破产价用的
   * @param positionType
   * @param marginCount
   * @param averageOpenPrice
   * @param holdCount
   * @param diffRate
   * @return {Decimal}
   */
  calPrice(
    positionType: number,
    marginCount: Decimal,
    averageOpenPrice: Decimal,
    holdCount: Decimal,
    diffRate: Decimal,
    keepMarginRate: Decimal
  ): Decimal {
    if (holdCount.isZero()) {
      return new Decimal(0);
    }

    // 多仓为false 空仓为true
    let upDirection = positionType === HoldSideEnum.SHORT_POSITIONSl;
    let averageOpenValue = this.calValue(holdCount, averageOpenPrice, upDirection);

    if (!upDirection) {
      // 多仓
      let temp = averageOpenValue.add(marginCount);
      return holdCount.times(new Decimal(1).add(diffRate)).div(temp)
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
    } else {
      // 空仓
      let temp = averageOpenValue.sub(marginCount)
      return holdCount.times(new Decimal(1).sub(diffRate)).div(temp)
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_DOWN);
    }
  },

  /**
   * 计算破产价
   * @param positionType
   * @param holdCount
   * @param averageOpenPrice
   * @param marginCount
   * @return {Decimal}
   */
  calBankruptcyPrice(
    positionType: number,
    holdCount: Decimal,
    averageOpenPrice: Decimal,
    marginCount: Decimal,
  ): Decimal {
    return this.calPrice(positionType, marginCount, averageOpenPrice, holdCount, new Decimal(0), new Decimal(0));
  },

  /**
   * 计算保证金率
   * @param holdCount
   * @param marginCount
   * @param markPrice
   * @param upnl
   * @return {Decimal}
   */
  calMarginRate(
    holdCount: Decimal,
    marginCount: Decimal,
    markPrice: Decimal,
    upnl: Decimal
  ): Decimal {
    return marginCount.add(upnl).times(markPrice).div(holdCount).toDP(SERVER_CAL_PLACE, Decimal.ROUND_DOWN);
  },

  /**
   * 根据收益计算平仓价格
   * 反多 数量 / （仓位价值 + 收益）
   * 反空 数量 / （仓位价值 - 收益）
   * @param positionType 仓位类型
   * @param count 张数
   * @param openPrice 开仓价格
   * @param profits 想要的收益
   */
  calClosePrice(
    positionType: UnionPositionType,
    count: Decimal,
    openPrice: Decimal,
    profits: Decimal
  ): Decimal {
    if (openPrice.isZero()) {
      return new Decimal(0);
    }

    // 开仓价值
    let openValue = count.div(openPrice).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP);
    if (positionType === HoldSideEnum.LONG_POSITIONS) {
      if (profits.greaterThan(openValue)) {
        // 反向的话收益不可能大于仓位价值
        return new Decimal(0);
      }
      // 向下
      return count.div(count.div(openPrice)
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP).sub(profits)).toDP(SERVER_CAL_PLACE, Decimal.ROUND_DOWN)
    } else {
      // 向上
      return count.div(count.div(openPrice)
        .toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP).add(profits)).toDP(SERVER_CAL_PLACE, Decimal.ROUND_UP)
    }
  }
}
