import { render, staticRenderFns } from "./joinTeam.vue?vue&type=template&id=925704ec&scoped=true"
import script from "./joinTeam.vue?vue&type=script&lang=js"
export * from "./joinTeam.vue?vue&type=script&lang=js"
import style0 from "./joinTeam.vue?vue&type=style&index=0&id=925704ec&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "925704ec",
  null
  
)

export default component.exports